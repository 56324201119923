@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Open+Sans:wght@400;600&display=swap");

:root {
  --background: #0d1b2a;
  --darkBlue: #1b263b;
  --lightBlue: #778da9;
  --offWhite: #e0e1dd;
}
* {
  font-family: montserrat;
  font-weight: 600;
}
html {
  background-color: var(--background);
  font-family: montserrat, OpenSans;
  font-weight: 600;
  overflow-y: scroll;
  scroll-behavior: smooth;
  z-index: 1;
}
body {
  margin: 0px;
}

a {
  text-decoration: none;
}
img {
  border: var(--lightBlue) 2px solid;
}
/* *{
    border: red solid 1px;
} */

/* Header */
.header {
  background-color: var(--darkBlue);
  display: flex;
  box-shadow: 0px 6px 23px -1px #000000;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: -104px;
  width: 100%;
  transition: top 0.3s ease-in-out;
}
.header.sticky {
  top: 0;
}

.logo {
  align-self: flex-start;
}
.logo img {
  width: 100%;
  max-height: 100px;
  align-self: flex-start;
  border: none;
}

/* nav*/
.mainNav {
  display: flex;
  /* gap: 50px; */
  transition: 0.4s;
}

.navlinks {
  list-style: none;
  overflow: hidden;
}
li {
  float: left;
}
.nav {
  display: flex;
  align-items: center;
  gap: 50px;
}

.nav a {
  color: var(--offWhite);
  padding-right: 20px;
  font-size: 24px; /*breaks under 1000px screen size */
}
.nav a:hover {
  color: var(--lightBlue);
  transition: 0.5s;
}
.nav .btn {
  /* margin-right: -20px; */
  margin-left: 45px;
}

.btn {
  border: var(--lightBlue) solid 2px;
  border-radius: 15px;
  padding: 10px;
  color: var(--lightBlue);
}
.btn:hover {
  transition: 0.5s;
  background-color: var(--lightBlue);
  color: var(--offWhite);
}

/* MAIN */
main {
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 5%;
}

h2 {
  color: var(--lightBlue);
  font-size: 55px;
  text-align: center;
  margin-top: 3.2em;
}
p {
  color: var(--offWhite);
  font-size: 24;
}
/* Landing page */

.hi {
  margin-top: 10em;
  color: var(--lightBlue);
  font-size: 24;
  margin-bottom: 0;
}
h1 {
  color: var(--offWhite);
  font-size: 7em;
  margin: 0px;
  font-family: montserrat;
  font-weight: 600;
}
.landing h2 {
  text-align: left;
  margin: 0;
}
.landing p {
  margin-right: 30vw;
}

/* About me */

.about {
  align-self: center;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: auto;
}
.abtMe {
  display: flex;
  justify-content: flex-end;
  width: auto;
  display: inline-block;
}
.about p {
  margin-top: 0;
  width: 45%;
  margin-right: none;
}
.about img {
  width: auto;
  height: auto;
  max-height: 285.25px;
  max-width: 504px;
}

/* projects */

.projects {
  justify-content: center;
  display: flex;
  gap: 10em;
  margin-bottom: 2.5em;
  /* margin-left: 10em; */
  /* margin: auto; */
}
.projects-flipped {
  display: flex;
  flex-direction: row-reverse;
  gap: 10em;
  margin-bottom: 2.5em;
}
.projImgs {
  width: 30%;
  max-width: 500px;
  min-width: 300px;
}
.projectimg {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.projimga :hover {
  scale: 1.02;
  transition: 0.5s;
  box-shadow: 0px 10px 25px 10px #000000;
  /* transition-timing-function: ease-in-out; */
}

.projDesc {
  max-width: 500px;
  height: fit-content;
}
.projDesc a {
  color: white;
  font-size: 1.5em;
  text-decoration: none;
}
.hover-container:hover h3,
.hover-container:hover p ~ h3 {
  color: var(--lightBlue);
  transition: 0.5s;
}

.projectSkills img {
  width: 100%;
  border: none;
}
.projectSkills {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}
h3 {
  margin: 0;
  color: var(--offWhite);
  text-align: left;
}
.projects p {
  text-align: left;
  color: var(--offWhite);
  font-size: 0.7em;
}

/* The skills images need to stay within the project img */

/* more projects */
.moreProj h2 {
  color: var(--offWhite);
  font-size: 45px;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.gridItem {
  background-color: var(--lightBlue);
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  box-shadow: none;
}
.gridContainer a:hover {
  box-shadow: 1px 8px 21px 5px #000000;
  transition: 0.5s;
}
.gridContainer a:active {
  box-shadow: none;
  transition: 0.2s;
}
.gridItem h3 {
  color: var(--darkBlue);
}
svg {
  height: 25px;
}
.showMorebtn {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
/* Experience */
.skillsList {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: auto;
  margin-bottom: 25px;
  /* border: #fff solid 1px; */
}
.skillTitle {
  display: flex;
  justify-content: center;
  margin: 5px;
}
/* Education */

.eduItem {
  display: flex;
  background-color: var(--darkBlue);
  gap: 50px;
  width: 60%;
  margin: auto;
  margin-bottom: 25px;
}
.imgEduContainer {
  display: flex;
  align-items: center;
}
.eduItem img {
  width: 25%;
  border: none;
  height: 100%;
  align-self: center;
  margin-left: 50px;
}

.eduDesc {
  color: var(--lightBlue);
  margin: 10px;
  align-self: center;
}
.degree {
  color: var(--offWhite);
  font-weight: 600;
}
.time {
  color: var(--lightBlue);
}
.schoolDesc {
  color: var(--offWhite);
  font-size: small;
}
/* Socials */
.socials {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: fixed;
  left: 35px;
  top: 75%;
}
.socials a {
  width: fit-content;
}
.social {
  display: inline-flex;
  width: 30px;
  height: 30px;
  /* transition-duration: 1s; */
  border: none;
  filter: invert(60%) sepia(16%) saturate(633%) hue-rotate(174deg)
    brightness(88%) contrast(84%);
}
.social:hover {
  filter: invert(92%) sepia(1%) saturate(713%) hue-rotate(34deg)
    brightness(107%) contrast(78%);
  outline-offset: 10px;
  animation: shake 0.7s;
}
@keyframes shake {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
}

/* skills */

.skillsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.skillitem {
  width: 100%;
}
.skillitem img {
  width: 80%;
  border: none;
  aspect-ratio: 4/3;
  object-fit: contain;
}
.progOutline {
  width: 80%;
  height: 10px;
  border: var(--darkBlue) 2px solid;
  border-radius: 15px;
}
.progress {
  background-color: var(--lightBlue);
  border-radius: 15px;
  height: 10px;
}
#contact p {
  text-align: left;
}
footer {
  text-align: center;
  color: var(--offWhite);
  font-size: smaller;
  margin-top: 70px;
  margin-bottom: 10px;
}

/* media query's */

.mobileNav {
  display: block;
}
.hamMenu {
  display: none;
}
.mNav {
  display: none;
}
@media only screen and (max-width: 1025px) {
  .projDesc h3 {
    text-align: center;
  }
  aside {
    display: flex;
    justify-content: center;
  }
  .socials {
    margin: 10px;
    display: flex;
    flex-direction: row-reverse;
    gap: 50px;
    position: relative;
    left: 0;
    top: 0;
    align-self: center;
    /* left: 35px; */
    /* top: 75%; */
  }
  .projects {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }
  .projects-flipped {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }
  .projects p {
    text-align: center;
  }
  .projImgs {
    width: auto;
  }
  .skillsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .fixed {
    position: fixed;
  }
  .blur {
    /* filter: blur(4px); */
    z-index: -1;
  }
  .mainNav {
    display: none;
  }
  .hamMenu {
    display: block;
    margin-right: 10px;
    position: absolute;
    right: 10px;
    z-index: 1000;
  }
  /* .displayed {
    transition: 5s;
  } */
  .mobileNav {
    padding-top: 75px;
    display: block;
    position: absolute;
    height: fit-content;
    width: 45vw;
    top: 0;
    right: 0;
    background-color: var(--darkBlue);
    color: #000000;
    z-index: 100;
    opacity: 100;
    box-shadow: 0px 0px 23px -1px #000000;
    transition: transform 0.5s ease-in-out;
    transform: translate(200px);
  }
  .mobileNav.active {
    transition: 10s;
    /* right: 0; */
    display: block;
    transform: translate(0);
  }
  .navlinks {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 2em;
    text-align: center;
    padding: 0;
  }
  .navlinks a {
    color: var(--offWhite);
    font-size: 1.2em;
    text-decoration: none;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .btn {
    width: fit-content;
    /* margin-left: 8vw; */
  }
  label {
    display: flex;
    flex-direction: column;
    width: 70px;
    cursor: pointer;
  }

  label span {
    background: #fff;
    border-radius: 10px;
    height: 7px;
    margin: 7px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(8px, 0px);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(30px, -11px) rotatez(45deg);
  }
}
@media only screen and (max-width: 768px) {
  .eduItem {
    width: 100%;
  }
  .about {
    flex-direction: column;
  }
  .about p {
    width: auto;
    text-align: center;
  }

  .about img {
    max-width: 55%;
    /* min-width: 25%; */
    max-height: 100%;
    /* border: var(--lightBlue) 2px solid; */
    object-fit: contain;
    box-sizing: border-box;
    margin: auto;
  }
  h1 {
    font-size: 5em;
  }
  h2 {
    font-size: 2em;
  }
  .landing p {
    font-size: 1em;
    /* text-align: center; */
    margin-right: auto;
  }
  .skillsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
/* deploy and check nav anchors */
@media only screen and (max-width: 389px) {
  .mNav {
    display: none;
  }
  h1 {
    /*responive font size*/
    font-size: 3em;
  }
  .eduItem {
    justify-content: center;
    text-align: center;
  }
  .mobileNav.displayed {
    display: block;
    position: absolute;
    height: fit-content;
    width: 100%vw;
    top: 0;
    right: 0;
    background-color: var(--darkBlue);
    color: #000000;
    z-index: 100;
    opacity: 100;
    box-shadow: 0px 6px 23px -1px #000000;
    /* transform: translateX(-200px); */
  }
}
